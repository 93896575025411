<template>
    <sys-register-base
      :loading="loading"
      @onSave="save"
      :model.sync="item"
      :title="model && item.id ? `Atualizar status` : `Cadastrar status`"
      icon="mdi-alert-circle"
      v-bind:show.sync="dialog"
      maxWidth="40%"
    >
        <template v-slot:form>
            <v-row class="mt-1">
                <v-col cols="12" v-if="message">
                    <v-alert
                        outlined
                        color="warning"
                        prominent
                        border="left"
                        >
                        {{ message }}
                        </v-alert>
                </v-col>
                <EmcFormTextField 
                    v-model="item.name" 
                    label="Nome*" 
                    md="12"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.name"
                />
                <EmcFormTextField 
                    v-model="item.icon" 
                    label="Icone" 
                    md="6"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.icon"
                />
                <EmcFormTextField 
                    v-model="item.color" 
                    label="Cor" 
                    md="4"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.color"
                />
                <EmcFormTextField 
                    v-model="item.order" 
                    label="Ordem" 
                    md="2"
                    outlined
                    :error="errors.length > 0"
                    :error-messages="errors.order"
                />
            </v-row>

            <v-card outlined class="mt-4">
                <v-card-text>
                    <v-row
                        align="center"
                        justify="center"
                    >
                    <v-col cols="12">
                        <p class="text-center">
                            Etapa do status
                        </p>
                    </v-col>
                        <v-btn-toggle v-model="item.status_step" dense group>
                            <v-btn value="1" large elevation="2" class="px-4">
                                Início
                                <v-icon right>mdi-ray-start</v-icon>
                            </v-btn>
                            <v-btn value="2" large elevation="2" class="px-4">
                                Em Progresso
                                <v-icon right>mdi-ray-vertex</v-icon>
                            </v-btn>

                            <v-btn value="3" large elevation="2" class="px-4">
                                Finalização
                                <v-icon right>mdi-ray-end</v-icon>
                            </v-btn>

                            <v-btn value="4" large elevation="2" class="px-4">
                                Cancelamento
                                <v-icon right>mdi-cancel</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </v-row>
                </v-card-text>
            </v-card>

        </template>
    </sys-register-base>
</template>

<script>

import { mapActions } from 'vuex'

export default {
    name: "TicketFormRegisterStatus",
    data () {
        return {
            loading: false,
            message: null,
            errors: {}
        }
    },
    props: {
        show: { type: Boolean, default: false },
        model: { default: null },
        title: { default: "título" }
    },
    computed: {
        dialog: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        },
        item: {
            get() {
                return this.model
            },
            set(value) {
                this.$emit('model', value)
            }
        }
    },
    methods: {
        ...mapActions("ticket", ["ActionTicketStatusesStore", "ActionTicketStatusesUpdate"]),
        initVariables() {
            this.loading = !this.loading
            this.message = null
            this.errors = {}
        },
        save(edit) {
            
            this.initVariables()

            if(edit) {
                this.ActionTicketStatusesUpdate(this.model)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            } else {
                this.ActionTicketStatusesStore(this.model)
                    .then((res) => {
                        this.message = null;
                        this.$emit('onCompleted', res.data);
                    })
                    .catch((error) => {
                        this.errors     = error.errors;
                        this.message    = error.message;
                    })
                    .finally(() => {
                        this.loading = !this.loading
                    })
            }
        }
    }
}
</script>